<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>编辑合成</h3>
    </header>

    <main class="x-main">
      <!-- 标题类型 -->
      <el-card class="mb30">
        <div slot="header">
          <span>基本信息：</span>
        </div>
        <el-form
          :model="form"
          label-width="150px"
          :rules="rules"
          ref="myForm"
          required
        >
          <el-form-item label="选择合成藏品：" prop="NFTID">
            <el-select placeholder="请选择合成藏品" v-model="form.NFTID">
              <el-option
                v-for="(item, index) in optionsNft"
                :key="index + 1"
                :label="item.NFTName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择启用状态：" prop="status">
            <el-switch
              v-model="form.status"
              active-value="1"
              inactive-value="2"
            ></el-switch>
          </el-form-item>
          <el-form-item label="设置合成材料：" prop="NFTComposeRule">
            <el-table :data="form.NFTComposeRule" stripe style="width: 100%">
              <el-table-column prop="needNFTID" label="合成材料" width="280">
                <template slot-scope="scope">
                  <el-select
                    placeholder="请选择合成藏品"
                    v-model="scope.row.needNFTID"
                    disabled
                  >
                    <el-option
                      v-for="(item, index) in optionsNft"
                      :key="index"
                      :label="item.NFTName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="count"
                label="所需数量"
                width="220"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <div class="flex-box-around">
                    <el-link
                      :underline="false"
                      type="primary"
                      @click="doDelete(scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          round
          class="rights"
          @click="doOpen"
          >添加材料</el-button
        >
        <el-row type="flex" justify="center" class="mt10">
          <el-col :span="6">
            <el-button type="primary" @click="doValidate">提交编辑</el-button>
            <el-button @click="goBack">返回上级页面</el-button>
          </el-col>
        </el-row>
      </el-card>
    </main>

    <!-- 添加信息面板 -->
    <composePanel
      :dialogShow.sync="dialogShow"
      ref="composePanel"
      @update="acceptData"
    ></composePanel>
  </div>
</template>

<script>
import { NftQuery } from "@/api/module/nft";
import { ComposeUpdate, ComposeDetail } from "@/api/module/compose";
let Brarules = {
  status: [
    {
      required: true,
      message: "请选择启用状态",
      trigger: "change",
    },
  ],
  NFTID: [
    {
      required: true,
      message: "请选择合成藏品",
      trigger: "change",
    },
  ],
  NFTComposeRule: [
    {
      required: true,
      message: "请添加合成材料",
      trigger: "blur",
    },
  ],
};
export default {
  name: "ComposeUpdate",
  props: ["id"],
  components: {
    composePanel: () => import("./components/composePanel.vue"),
  },
  data() {
    return {
      dialogShow: false,
      optionsNft: [],
      form: {
        id: "",
        NFTID: "",
        NFTComposeRule: [],
        status: "",
      },
      rules: Brarules,
    };
  },
  created() {
    this.getOptionsNft();
    this.getComposeInfo();
  },
  methods: {
    async getComposeInfo() {
      const r = await ComposeDetail(this.id);
      this.form = {
        id: r.id,
        NFTID: r.NFTID,
        NFTComposeRule: r.NFTComposeRule,
        status: r.status,
      };
    },
    doValidate() {
      let f = this.form;
      //   新增
      let oData = {
        NFTID: f.NFTID,
        NFTComposeRule: f.NFTComposeRule,
        status: f.status,
      };
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          const r = await ComposeUpdate(f.id, oData);
          if (r.code != 0) return;
          if (r.code == 0) {
            this.$message({
              type: "success",
              message: "编辑成功",
              duration: 1300,
              onClose: () => {
                this.goBack();
              },
            });
          }
        } else {
          //全局验证不通过
        }
      });
    },
    doDelete(val) {
      const tags = this.form.NFTComposeRule.filter(
        (item) => item.count !== val.count || item.needNFTID !== val.needNFTID
      );
      this.form.NFTComposeRule = tags;
    },
    goBack() {
      this.$router.go(-1);
    },
    doOpen() {
      this.dialogShow = true;
    },
    async getOptionsNft() {
      let query = `?pagination=false&page=1&pageSize=10&status=3`;
      const r = await NftQuery(query);
      this.optionsNft = r.list;
    },
    acceptData(val) {
      this.form.NFTComposeRule.push(val);
    },
  },
};
</script>

<style lang="less" scoped>
.rights {
  position: absolute;
  top: 350px;
  right: 660px;
}
</style>