import { get, post, put, remove } from "@/api/config";

// == nftCompose ==
export const ComposeQuery = (query) => get("v1/nftcompose" + query);

export const ComposeCreate = (params) => post("v1/nftcompose", params);

export const ComposeRemove = (id) => remove(`v1/nftcompose/${id}`);

export const ComposeUpdate = (id, params) => put(`v1/nftcompose/${id}`, params);

export const ComposeDetail = (id) => get(`v1/nftcompose/${id}`);




